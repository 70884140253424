import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//import { Link } from "gatsby"
import Link from '../components/Link'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../css/marketplace.scss'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import CustomButton from '../components/custom-button/custom-button.component.jsx'
import CustomCard from '../components/custom-card/custom-card.component.jsx'
import { openPopupWidget } from 'react-calendly'

import logo from '../images/logo.svg'
import market from '../images/market-place.svg'
import enterprise from '../images/enterprise-requests.svg'
import task from '../images/task.svg'
import robots from '../images/robots.svg'
import requests from '../images/requests.svg'
import automation from '../images/automation.svg'
import integration from '../images/marketplace-records.svg'
import marketBtn from '../images/market-btn.svg'
import enterpriseBtn from '../images/enterprise-btn.svg'

export const pageData = [
	{
		title: 'Full Automation is Our Thing',
		subtitle: 'Once a luxury, now is a necessity, automating repetitive business processes frees up humans for tasks that are less mundane or more valuable than those that can be completed by machines and software. The ability of these software programs to be adaptable over time means they can more quickly and effectively pore through massive troves of data and contextualize that information in a useful way for supporting internal decision making.',
	},
	{
		title: 'iCloud Ready For Integration With Your System of Records',
		subtitle: 'and ready to plug in with most of the world’s known systems of record',
	},
]
const Marketplace = ({ data }) => {
	const state = { productName: 'Market Place', planTitle: 'Starter Plan', planPrice: '99 $' }
	const signupClick = () => {
		localStorage.setItem(
			'state',
			JSON.stringify({
				productName: state.productName,
				planTitle: state.planTitle,
				planPrice: state.planPrice,
			})
		)
	}
	const url = 'https://calendly.com/icloudready/request-discovery'
	const marketData = {
		imageUrl: market,
		title: 'Market Place',
		priceText: '$99',
		subtitle: '/ Active user / Month Start from 100 Active User',
		linkStyle: {
			display: 'block',
			width: '56px',
			height: '56px',
		},
		buttonStyle: {
			display: 'block',
			width: '56px',
			height: '56px',
			padding: '0',
			margin: 'auto',
			background: `url(${marketBtn}) center/contain no-repeat`,
		},
		linkLearnStyle: { display: 'none' },
		linkTo: '/signup',
		linkLearnTo: '/',
		state: {
			productName: 'Market Place',
			planTitle: 'Starter',
			planPrice: '$99',
		},
	}
	const enterpriseData = {
		imageUrl: enterprise,
		title: 'Enterprise Requests',
		priceText: 'Contract Based',
		subtitle: '/ Active user / Month Start from 100 Active User',
		linkStyle: { display: 'none' },
		buttonStyle: {
			display: 'block',
			width: '56px',
			height: '56px',
			padding: '0',
			margin: 'auto',
			background: `url(${enterpriseBtn}) center/contain no-repeat`,
		},
		linkLearnStyle: { display: 'none' },
		linkTo: '/pricing',
		linkLearnTo: '/',
		state: {
			productName: 'Market Place',
			planTitle: 'Enterprise Requests',
			planPrice: '$99',
		},
	}
	return (
		<Fragment>
			<Layout>
				<Helmet>
					<meta name="robots" content="noindex" />
				</Helmet>
				{/* <SEO title="Market Place" /> */}
				<div className="marketplace-page">
					<div className="Header-parent">
						<MDXRenderer>{data.Header.nodes[0].body}</MDXRenderer>
					</div>
					<div className="page-content marketplace-content">
						<div className="marketplace-section">
							<div className="header-content">
								<div className="header-items">
									<h1>Services Requests</h1>
									<div className="hint">
										<h3>
											<span>iCloud Ready Markeplace</span>
											<span>
												{' '}
												Ready to Run a Requests in a Click ,
												You can Choose from More than 1000
												Different requests to Automate your
												Industry specific
											</span>
										</h3>
									</div>
									<CustomButton>
										<Link
											className="demo-request"
											data-track-location="marketplace"
											to="/pricing"
										>
											Request Demo
										</Link>
									</CustomButton>
								</div>
								{/* <div className="header-cards">
                            <div className="market-card active">
                                <CustomCard
                                    imageUrl={marketData.imageUrl}
                                    title={marketData.title}
                                    priceText={marketData.priceText}
                                    subtitle={marketData.subtitle}
                                    linkStyle={marketData.linkStyle}
                                    buttonStyle={marketData.buttonStyle}
                                    linkLearnStyle={marketData.linkLearnStyle}
                                    linkTo={marketData.linkTo}
                                    linkLearnTo={marketData.linkLearnTo}
                                    state={marketData.state}
                                    onBtnClick={()=> { 
                                    localStorage.setItem("state", JSON.stringify(marketData.state))}}
                                ></CustomCard>
                            </div>
                            <div className="market-card">
                                <CustomCard
                                    imageUrl={enterpriseData.imageUrl}
                                    title={enterpriseData.title}
                                    priceText={enterpriseData.priceText}
                                    subtitle={enterpriseData.subtitle}
                                    linkStyle={enterpriseData.linkStyle}
                                    buttonStyle={enterpriseData.buttonStyle}
                                    linkLearnStyle={enterpriseData.linkLearnStyle}
                                    linkTo={enterpriseData.linkTo}
                                    linkLearnTo={enterpriseData.linkLearnTo}
                                    state={enterpriseData.state}
                                    onBtnClick={()=> { 
                                    openPopupWidget({url})
                                    localStorage.setItem("state", JSON.stringify(enterpriseData.state))}}
                                    ></CustomCard>
                            </div>
                        </div> */}
							</div>
						</div>
						<section className="products-content" id="products-content">
							<h1 className="product-header">Explore Features</h1>
							<div className="products-container">
								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details task">
											<h3 className="title">
												Run a specific task by one click
											</h3>
											<p className="subtitle">
												By one Click you can run whatever
												you need and completely integrated
												with your system of recored
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={task}
												className="item-img"
												alt="task"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent reverse-parent">
										<div className="product-details results">
											<h3 className="title">
												Our robots will make your work and
												give you the results
											</h3>
											<p className="subtitle">
												Each running request is using a high
												quality robots to perform a job fast
												, secured and smart.
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={robots}
												className="item-img"
												alt="robots"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details">
											<h3 className="title">
												More than 1000 request for many
												business cases across multiple
												industries
											</h3>
											<p className="subtitle">
												We have more than 1000 requests
												ready for your industry specific
												solutions , we are keep growing to
												support more request and to help you
												to build your own.
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={requests}
												className="item-img"
												alt="requests"
											/>
										</div>
									</div>
								</div>
							</div>
						</section>
						<div className="automation-container">
							<CustomCard
								imageUrl={automation}
								title={pageData[0].title}
								subtitle={pageData[0].subtitle}
								linkStyle={{ display: 'none' }}
								buttonStyle={{ display: 'none' }}
								linkLearnStyle={{ display: 'none' }}
								linkTo="/"
								linkLearnTo="/"
							></CustomCard>
						</div>
						<div className="integration-container">
							<CustomCard
								imageUrl={integration}
								title={pageData[1].title}
								subtitle={pageData[1].subtitle}
								linkStyle={{ display: 'none' }}
								buttonStyle={{ display: 'none' }}
								linkLearnStyle={{ display: 'none' }}
								linkTo="/"
								linkLearnTo="/"
							></CustomCard>
						</div>
					</div>
					<div className="footer-section">
						<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
					</div>
				</div>
			</Layout>
		</Fragment>
	)
}
export const pageQuery = graphql`
	query MarketplaceQuery {
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`
export default Marketplace
